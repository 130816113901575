import * as React from 'react'
import Layout from '../../../../../layout'
import TeamCreatedPage from '../../../../../pagesComponents/groups/team-created'

const TeamCreated = ({ id, teamId }) => {
	return (
		<Layout theme="light" title="Team Created">
			<TeamCreatedPage id={id} teamId={teamId} />
		</Layout>
	)
}

export default TeamCreated
