import React, { useEffect, useContext } from 'react'
import DuckPage from '../../components/duckPage'
import { Button } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { getTempTeam, removeTempTeam } from '../../helpers/database'
import { RWebShare } from 'react-web-share'
import { useAuth } from '../../hooks/useAuth'
import eventTracking from '../../services/eventTracking'

const TeamCreated = ({ id, teamId }) => {
	const team = getTempTeam() || {}
	const { user } = useAuth()

	const shareUrl =
		typeof window !== 'undefined'
			? `${window.location.origin}/groups/${id}/team/${teamId}`
			: ''
	const data = {
		title: `Fairgame Team: ${team?.name || ''}`,
		text: `I’m inviting you to join the team “${
			team?.name || ''
		}” so we can play together at Fairgame`,
		url: shareUrl
	}

	const handleShare = async () => {
		if (typeof navigator !== 'undefined') {
			await navigator.share({
				text: `I’m inviting you to join the team “${
					team?.name || ''
				}” so we can play together at Fairgame: ${shareUrl}`
			})
		}

		eventTracking('share_team', {
			group_id: id,
			team_id: teamId,
			user_id: user?.uid
		})
	}

	const afterShare = () => {
		eventTracking('share_team', {
			group_id: id,
			team_id: teamId,
			user_id: user?.uid
		})
	}

	useEffect(() => {
		removeTempTeam()
	}, [])

	return (
		<DuckPage
			theme="light"
			title={
				<>
					add your <br />
					team mates
				</>
			}
			topText="Success! Your new team has been created!"
			subtitle="Share your new team with the other members of your group before you start playing!"
			primaryBtn={
				typeof navigator !== 'undefined' && navigator.share ? (
					<Button
						mb="50px"
						w="100%"
						variant="secondary"
						onClick={handleShare}
					>
						Share Team
					</Button>
				) : (
					<RWebShare data={data} onClick={() => afterShare()}>
						<Button mb="50px" w="100%" variant="secondary">
							Share Team
						</Button>
					</RWebShare>
				)
			}
			secondaryBtn={
				<Button
					variant="primary"
					onClick={() => navigate(`/groups/${id}/team/${teamId}/`)}
					mb="30px"
				>
					View Team
				</Button>
			}
		></DuckPage>
	)
}
export default TeamCreated
